@import "../../../css/helpers";

.options_panel__section_title {
  margin-bottom: 20rem;

  font-size: 20rem;
  font-weight: 700;
}

.options_panel__checkboxes {
  @extend %flex_row_wrap_flex_start_stretch;

  margin: 0 -10rem -10rem 0;

  @include bp($point_1, $point_4 + 1) {
    margin: 0 -8rem -8rem 0;
  }

  @include bp($point_4, $point_6 + 1) {
    margin: 0 -10rem -10rem 0;
  }

  @include bp($point_6) {
    margin: 0 -8rem -8rem 0;
  }
}

.checkbox_item {
  width: 25%;
  padding: 0 10rem 10rem 0;

  opacity: .4;
  pointer-events: none;

  @include bp($point_1, $point_4 + 1) {
    width: 33.33333%;
    padding: 0 8rem 8rem 0;
  }

  @include bp($point_4, $point_6 + 1) {
    width: 25%;
    padding: 0 10rem 10rem 0;
  }

  @include bp($point_6) {
    width: 33.33333%;
    padding: 0 8rem 8rem 0;
  }

  &.checkbox_item--available {
    opacity: 1;
    pointer-events: initial;
  }

  &.checkbox_item--hide {
    position: absolute;
    opacity: 0;
    user-select: none;
    pointer-events: none;
  }
}

.checkbox_item__in {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  user-select: none;
}

.checkbox__element {
  display: block;
  width: 100%;
  height: 100%;
}

.checkbox__label {
  @extend %flex_column_wrap_flex_start;
  @extend %transition_color_background_border;

  width: 100%;
  height: 100%;
  //min-height: 200rem;

  border: 2rem solid $white_2;
  border-radius: 10rem;
  overflow: hidden;

  background: $white;
  cursor: pointer;

  .checkbox_item--checked & {
    background: $red;
    border: 2rem solid $red;

    .checkbox__title,
    .checkbox__price {
      color: $white;
    }
  }
}

.checkbox__img {
  position: relative;

  display: block;
  width: 100%;
  padding-top: 100%;

  background-color: $white;
  overflow: hidden;

  .image_hor_flip & {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.checkbox__label_in {
  @extend %flex_column_wrap_flex_start;

  width: 100%;
  padding: 10rem 3rem 7rem;
  user-select: none;

  @include bp($point_7) {
    min-height: 101rem !important;
  }
}

.checkbox__title {
  @extend %font-text-2;
  @extend %transition_color;

  display: block;
  width: 100%;
  padding-bottom: 12rem;
  line-height: 1.1;
}

.checkbox__price {
  @extend %font-text-2;
  @extend %transition_color;

  display: block;
  margin-top: auto;

  color: $red;
  line-height: 1.1;
}
