@import "../../../css/helpers";

/* SIDEBAR */

#options_panel {
  transition: transform 300ms ease-in-out;
  overflow: hidden;

  @include bp($point_4 + 1, min) {
    width: var(--option-panel-width);
    height: 100vh;
  }

  @include bp($point_4) {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    margin-bottom: 110rem;
    margin-top: var(--canvas-wrapper-mobile-height);
  }

  &.hide {
    display: none;
  }

  form {
    overflow: hidden;
  }
}

.options_panel__in {
  display: block;

  padding: 47rem 10rem 17rem;

  overflow-x: hidden;
  background-color: $white;

  @include bp($point_1) {
    padding: 47rem 6rem 17rem;
  }

  @include bp($point_4 + 1, min) {
    height: calc(100vh - var(--canvas-wrapper-desktop-height));
  }

  @include bp($point_4) {
    max-height: calc(100vh - var(--canvas-wrapper-mobile-height) - 100rem);
    height: 100%;
    padding-top: 12rem;
    padding-bottom: 80rem;

    overflow-x: hidden;
    -ms-overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-y: auto;
  }

  @media screen and (max-height: 600px) and (min-width: $point_4) {
    height: calc(100vh - 110rem);
  }
}

.options_panel__options_list {
  @include bp($point_4) {
    margin-bottom: -30rem;

    overflow: hidden;
    -ms-overflow: hidden;
  }
}

.options_panel__title {
  @extend %font-2;

  margin-bottom: 10rem;

  @include bp($point_4) {
    display: none;
  }
}

.options_panel__options_list {
  //margin-bottom: 0;
}

.options_panel__section {
  margin-bottom: 30rem;

  .options_panel__header {
    @extend %flex_row_wrap_flex_start_center;
    @extend %font-3;

    margin-bottom: 10rem;

    .title {
      margin-right: 20rem;
      font-weight: 500;
    }
  }
}


/* FOOTER */

.options_footer {
  padding: 10rem 10rem;

  background-color: $white;

  @include bp($point_4 + 1, min) {
    position: fixed;
    bottom: 0;
    right: 0;
    width: var(--option-panel-width);
    box-shadow: 10px -1rem 20rem -14rem #98261C;
  }

  @include bp($point_4) {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    box-shadow: 10px -1rem 20rem -14rem #98261C;

    padding: 0 4rem 6rem;

    transition: transform .3s ease;
    will-change: transform;
    transform: translateY(200rem);
  }

  @include bph(600px) {
    padding: 3rem 6rem;
  }

  &.show_half {
    transform: translateY(66rem);
  }

  &.show {
    transform: translateY(0);
  }

  .options_footer__total_price {
    @extend %flex_row_wrap_flex_end_baseline;
    @extend %font-text;

    padding: 0 0 4rem 0;

    @include bp($point_4) {
      display: none;
    }

    .title {
      font-size: 13rem;
      margin-right: auto;
    }

    .price {
      font-size: 20rem;
      font-weight: 700;
      margin-left: 10rem;
      color: $red;
    }

    .notification_taxes {
      font-size: 12rem;
      margin-right: 10rem;
    }

    .amount {
      font-size: 20rem;
    }

    .currency_symbol {
      margin-left: 5rem;
    }
  }

  .options_footer__tabs {
    padding: 20rem 0 6rem 0;
    border-top: 1rem solid $red;

    @include bph(600px) {
      padding: 5rem 0;
    }

    @include bp($point_4) {
      padding-top: 8rem;
    }
  }

  .options_footer__tabs_list {
    @extend %flex_row_wrap_flex_start;

    margin: 0 -10rem -10rem 0;
  }

  .options_footer__tab {
    width: 25%;
    padding: 0 10rem 10rem 0;

    .title {
      @extend %transition_color_background;

      width: 100%;
      padding: 10rem 3rem;

      color: $black;
      font-size: 12rem;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;

      @include bph(600px) {
        padding: 3rem;
      }
    }

    .icon_wrap {
      @include bp($point_4 + 1, min) {

      }

      @include bp($point_4) {
        display: none;
      }
    }

    .icon {
      width: 60rem;
      height: 60rem;
      color: $black_2;
      display: block;

      @include bph(600px) {
        width: 40rem;
        height: 40rem;
      }
    }

    &.active {
      .title {
        color: $white;
        background: $red;
      }
      .options_footer__tab_in {
        border-color: $red;
      }
    }
  }

  .options_footer__tab_in {
    @extend %flex_column_wrap_flex_start_center;

    border: 2rem solid $white_2;
    border-radius: 10rem;
    overflow: hidden;
    cursor: pointer;
    transition: border-color .3s ease;
    will-change: border-color;

    .options_footer__tab.active & {
      border-color: $red;
    }
  }

  .options_footer__button_wrap {

    &.options_footer__button_wrap--desktop {
      @include bp($point_4) {
        display: none;
      }
    }

    &.options_footer__button_wrap--mobile {
      @extend %flex_row_wrap_flex_start_center;

      @include bp($point_4 + 1, min) {
        //display: none;
        user-select: none;
        pointer-events: none;
        opacity: 0;
        position: absolute;
      }

      .form_button_summary {
        width: 156rem;
        padding-bottom: 2rem;
        line-height: 40rem;
      }

      .price_wrapper {
        @extend %flex_row_wrap_flex_start_flex_end;

        height: 100%;
        margin-left: auto;
      }

      .title {
        margin-bottom: 2rem;
        font-size: 13rem;
        font-weight: 700;
      }

      .price {
        @extend %flex_column_wrap_flex_start_flex_end;

        font-size: 20rem;
        font-weight: 700;
        margin: 0 5rem 0 32rem;

        .notification_taxes {
          font-size: 10rem;
          font-weight: 500;
          color: #222222;
        }

        .amount {
          font-size: 20rem;
          font-weight: 700;
          color: $red;
        }

        .currency_symbol {
          margin-left: 5rem;
        }
      }
    }


  }

  .options_footer__footer_mobile {

    .price {
      font-size: 20rem;
      font-weight: 700;
      margin-left: 10rem;
      color: $red;
    }

    .notification_taxes {
      font-size: 12rem;
      margin-right: 10rem;
    }

    .amount {
      font-size: 20rem;
    }

    .currency_symbol {
      margin-left: 5rem;
    }
  }

  .form_button_summary {
    @extend %font-1;
    @extend %transition_opacity;

    width: 100%;
    padding: 0 5rem;

    color: $white;
    background-color: $red;

    font-size: 20rem;
    line-height: 54rem;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    border-radius: 10rem;

    &[disabled="true"] {
      opacity: .4;
      user-select: none;
      pointer-events: none;
    }

    &:hover {
      opacity: .6;
    }

    @include bph(600px) {
      font-size: 14rem;
      line-height: 30rem;
    }
  }
}


/* Contact Form */
.contact_form {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.38);
  display: flex;
  align-items: center;
  justify-content: center;

  .contact_form__wrapper {
    position: relative;
    padding: 40px 20px 30px;
    background: #F2F2F2;
    max-width: min(98%, 700px);
    max-height: 98.4vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .contact_form__close_btn {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px 10px 0 0;

    .contact_form__close_icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32rem;
      height: 32rem;
      color: #98261C;
      cursor: pointer;
      opacity: .2;
      transition: opacity 200ms ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }

  .contact_title {
    @extend %font-2;

    margin-bottom: 20px;

    @include bp($point_4) {
      font-size: 16px;
    }
  }

  .contact_desc {
    @extend %font-2;

    margin-bottom: 20px;
    max-width: 700px;
    font-weight: 500;

    @include bp($point_4) {
      font-size: 14px;
    }
  }

  .contact_form__form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .contact_form__input {
    width: 100%;
    color: #222222;
    margin-bottom: 15px;

    @include bp($point_4 + 1, min) {
      width: 49%;
    }

    @include bp($point_4) {
      width: 100%;
    }

    &.contact_form__input--submit {
      @include bp($point_4) {
        order: 1;
      }
    }

    input {
      @extend %font-3;

      padding: 10px 35px;
      border: none;
      width: 100%;
      font-weight: 400;

      @include bp($point_4) {
        font-size: 14px;
      }

      &::placeholder {
        color: #7e7e7e;
        opacity: 1; /* Firefox */
      }

      &::-ms-input-placeholder { /* Edge 12 -18 */
        color: #7e7e7e;
      }

      &.error {
        outline: 1px solid red;
        outline-offset: 5px;
        border-radius: 2px;
      }

      &[type="submit"] {
        background: #98261C;
        color: #fff;
        cursor: pointer;
        transition: opacity 200ms ease-in-out;

        &:hover, &:focus {
          opacity: .6;
        }

        &:disabled,
        &[disabled]{
          background-color: #cccccc;
          color: #666666;
          opacity: 1;
          cursor: initial;
        }

      }
    }
  }

  .contact_form__checkbox {
    width: 100%;
    margin-bottom: 15px;
    font-size: 16px;
    transition: opacity 200ms ease-in-out;

    label {
      display: flex;
      width: max-content;
      cursor: pointer;
      max-width: 100%;

      @include bp($point_4) {
        font-size: 14px;
      }

      &:hover, &:focus {
        opacity: .6;
      }
    }

    input {
      accent-color: #98261C;

      &.error {
        outline: 1px solid red;
        outline-offset: 5px;
        border-radius: 2px;
      }
    }

    span {
      display: inline-block;
      margin-left: 10px;
    }
  }

  .contact_form__spinner {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100px;
      height: 100px;
      color: #98261c;
      transform: translate(-50%, -50%);
    }
  }
}

// Message info
.contact_form__info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px;
  transform: translate(0, -100%);
  transition: transform 200ms ease-in-out;
  background: #63c753;

  &.show {
    transform: translate(0, 0);
  }

  .close_icon {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    width: 30px;
    height: 30px;

    color: #ffffff;
    cursor: pointer;
  }

  .info_text {
    @extend %font-text;

    margin-left: 60px;
    color: #ffffff;
  }
}