//--------------------------------------------- Typography

%font-1 {
  font-size: 30rem;
  font-weight: 700; // bold
  line-height: 1.4;
}

%font-2 {
  font-size: 20rem;
  font-weight: 700; // bold
  line-height: 1.4;
}

%font-3 {
  font-size: 16rem;
  font-weight: 500; // medium
  line-height: 1;
}

%font-text {
  font-size: 14rem;
  font-weight: 500;
  line-height: 1.4;
}

%font-text-2 {
  font-size: 13rem;
  font-weight: 400;
  line-height: 1.4;
}

.font-1 {
  @extend %font-1;
}
.font-2 {
  @extend %font-2;
}
.font-3 {
  @extend %font-3;
}
.font-text {
  @extend %font-text;
}

//--------------------------------------------- Typography####