@import "../../../css/helpers";

.show_options_button {
  @extend %flex_row_nowrap_center_center;
  @extend %font-text;
  @extend %transition_color;
  @extend %transition_opacity;

  position: fixed;
  top: 10rem;
  right: 10rem;
  z-index: 9;

  padding: 0;
  color: $black_2;

  cursor: pointer;
  border-radius: 0;
  border: 0;

  @include bp($point_3) {
    top: 0;
    right: 0;
    padding: 8rem;
  }

  @include bp($point_4) {
    transform: rotate(-90deg);
  }

  &.closed {
    @include bp($point_4 + 1, min) {
      transform: scale(-1, 1);
    }

    @include bp($point_4) {
      transform: rotate(90deg);
    }

    .show_options_button__icon.arrow_icon {
      display: flex !important;
    }
    .show_options_button__icon.close_icon {
      display: none !important;
    }
  }

  &:hover {
    color: $red;
    opacity: .6;
  }

  .show_options_button__icon {
    width: 32rem;
    height: 32rem;

    color: $red;

    @include bp($point_3) {
      width: 24rem;
      height: 24rem;
    }

    &.arrow_icon {
      display: none !important;
    }
  }
}