@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,900&display=swap");
.font-1 {
  font-size: 30rem;
  font-weight: 700;
  line-height: 1.4;
}

.font-2 {
  font-size: 20rem;
  font-weight: 700;
  line-height: 1.4;
}

.font-3 {
  font-size: 16rem;
  font-weight: 500;
  line-height: 1;
}

.font-text {
  font-size: 14rem;
  font-weight: 500;
  line-height: 1.4;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video, button {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
}
html:before, html:after, body:before, body:after, div:before, div:after, span:before, span:after, object:before, object:after, iframe:before, iframe:after,
h1:before,
h1:after, h2:before, h2:after, h3:before, h3:after, h4:before, h4:after, h5:before, h5:after, h6:before, h6:after, p:before, p:after, blockquote:before, blockquote:after, pre:before, pre:after,
abbr:before,
abbr:after, address:before, address:after, cite:before, cite:after, code:before, code:after,
del:before,
del:after, dfn:before, dfn:after, em:before, em:after, img:before, img:after, ins:before, ins:after, kbd:before, kbd:after, q:before, q:after, samp:before, samp:after,
small:before,
small:after, strong:before, strong:after, var:before, var:after,
b:before,
b:after, i:before, i:after,
dl:before,
dl:after, dt:before, dt:after, dd:before, dd:after, ol:before, ol:after, ul:before, ul:after, li:before, li:after,
fieldset:before,
fieldset:after, form:before, form:after, label:before, label:after, legend:before, legend:after,
article:before,
article:after, aside:before, aside:after, canvas:before, canvas:after, details:before, details:after, figcaption:before, figcaption:after, figure:before, figure:after,
footer:before,
footer:after, header:before, header:after, hgroup:before, hgroup:after, menu:before, menu:after, nav:before, nav:after, section:before, section:after, summary:before, summary:after,
time:before,
time:after, mark:before, mark:after, audio:before, audio:after, video:before, video:after, button:before, button:after {
  box-sizing: border-box;
}

sub,
sup {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent;
  box-sizing: border-box;
}
sub:before, sub:after,
sup:before,
sup:after {
  box-sizing: border-box;
}

a:before, a:after {
  box-sizing: border-box;
}

table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: none;
}

q:before, q:after {
  content: none;
}

:focus {
  outline: 0;
}

input[type=text], textarea {
  -webkit-appearance: none;
}
input[type=text]::-ms-clear, textarea::-ms-clear {
  display: none;
}

input,
textarea {
  border-radius: 0;
  box-sizing: border-box;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #222;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #222;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #222;
}
input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: transparent;
}
input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  color: transparent;
}

textarea {
  resize: none;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
  word-wrap: break-word;
}

:root {
  --full-screen: calc(var(--vh, 1vh) * 100);
  --option-panel-width: 480rem;
  --canvas-wrapper-mobile-height: 40vh;
  --canvas-wrapper-desktop-height: 230rem;
}
@media screen and (max-width: 1100px) {
  :root {
    --option-panel-width: 390rem;
  }
}

html {
  height: 100%;
  font: 400 0.5208333333vw/1.33 Poppins, Arial, sans-serif;
}
@media screen and (min-width: 376px) {
  html {
    font-size: 1px;
  }
}
@media screen and (max-width: 375px) {
  html {
    font-size: 0.2666666667vw;
  }
}

body {
  display: flex;
  height: 100%;
  font-size: 14rem;
  color: #222;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@supports (display: grid) {
  body {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  body {
    overflow: hidden;
  }
}

@media screen and (min-width: 769px) {
  .html,
  .body,
  .root,
  #root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
.preload_text {
  display: flex;
  flex-direction: column;
}
.preload_text .bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  animation-name: anim;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-direction: revert;
  animation-fill-mode: forwards;
}
@keyframes anim {
  from {
    left: 0;
  }
  to {
    left: 100%;
  }
}
.preload_text .progress {
  position: relative;
  width: 30%;
  height: 5px;
  margin-top: 20px;
  background: #f5f5f5;
  overflow: hidden;
}
.preload_text .progress span {
  display: block;
  position: absolute;
  top: 0;
  left: -100%;
  width: 50%;
  height: 100%;
  background: #98261c;
  animation-name: anim;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-direction: revert;
  animation-delay: 2000ms;
}
@keyframes anim {
  from {
    left: -100%;
  }
  to {
    left: 100%;
  }
}

.leva-c-kWgxhW-bCBHqk-fill-false {
  left: 50%;
}

