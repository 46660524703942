//---------------------------------------------fonts
$font_path: "../../fonts/";
$font_1: Poppins, Arial, sans-serif;
$font_size_base: 14rem;
//---------------------------------------------fonts###

//---------------------------------------------colors
$white: #fff;
$black: #000;

$white_2: #E8E8E9;
$black_2: #222;
$red: #98261C;

$color_text_base: $black_2;
$color_link_base: $black_2;
//---------------------------------------------colors###

//---------------------------------------------z-index
$z-index: (
	popup: 101,
	header: 100,
	footer: 100,
);
//---------------------------------------------z-index###

//---------------------------------------------layout
$page_width: 152rem;

//---------------------------------------------layout###

//---------------------------------------------global values
$gl_radius: 0;
//---------------------------------------------global values###

//---------------------------------------------media points
$point_1: 1100px;
$point_2: 1025px;
$point_3: 1024px;
$point_4: 768px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;

$point_9: 1260px;
//---------------------------------------------media points

//---------------------------------------------forms
$input_height: 4.1rem;
$input_offset: 0 2rem;
$input_font_size: 1.4rem;
$input_border_color: $white_2;
$input_border_color_active: $red;
$input_placeholder_color: $black_2;
$input_text_color: $black_2;
$input_radius: 2.1rem;
//---------------------------------------------forms###
