.additional_options .additional_option__radio .label, .additional_options .additional_option__radio_buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.font-1 {
  font-size: 30rem;
  font-weight: 700;
  line-height: 1.4;
}

.font-2 {
  font-size: 20rem;
  font-weight: 700;
  line-height: 1.4;
}

.font-3 {
  font-size: 16rem;
  font-weight: 500;
  line-height: 1;
}

.additional_options .additional_option__radio, .additional_options .additional_option__title, .font-text {
  font-size: 14rem;
  font-weight: 500;
  line-height: 1.4;
}

.additional_options {
  margin-top: 30rem;
}
.additional_options .additional_option__title {
  margin-bottom: 20rem;
  font-size: 13rem;
}
.additional_options .additional_option__radio_button:not(:last-child) {
  margin-right: 50rem;
}
.additional_options .additional_option__radio {
  display: block;
  font-size: 13rem;
  opacity: 0.4;
  pointer-events: none;
}
.additional_options .additional_option__radio.additional_option__radio--available {
  opacity: 1;
  pointer-events: initial;
}
.additional_options .additional_option__radio input {
  position: absolute;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  visibility: hidden;
}
.additional_options .additional_option__radio.radio--checked .label:before {
  border: 2.5rem solid #98261C;
}
.additional_options .additional_option__radio.radio--checked .label:after {
  display: block;
}
.additional_options .additional_option__radio .label {
  position: relative;
  height: 32rem;
  padding-left: 36rem;
  cursor: pointer;
}
.additional_options .additional_option__radio .label:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  content: "";
  display: block;
  width: 32rem;
  height: 32rem;
  margin-right: 4rem;
  border-radius: 50%;
  border: 3rem solid #222;
}
.additional_options .additional_option__radio .label:after {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(6rem, -50%);
  content: "";
  display: none;
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  background: #98261C;
}

