.show_options_button {
  transition: opacity 0.3s ease;
  will-change: opacity;
}

.show_options_button {
  transition: color 0.3s ease;
  will-change: color;
}

.show_options_button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.font-1 {
  font-size: 30rem;
  font-weight: 700;
  line-height: 1.4;
}

.font-2 {
  font-size: 20rem;
  font-weight: 700;
  line-height: 1.4;
}

.font-3 {
  font-size: 16rem;
  font-weight: 500;
  line-height: 1;
}

.show_options_button, .font-text {
  font-size: 14rem;
  font-weight: 500;
  line-height: 1.4;
}

.show_options_button {
  position: fixed;
  top: 10rem;
  right: 10rem;
  z-index: 9;
  padding: 0;
  color: #222;
  cursor: pointer;
  border-radius: 0;
  border: 0;
}
@media screen and (max-width: 1024px) {
  .show_options_button {
    top: 0;
    right: 0;
    padding: 8rem;
  }
}
@media screen and (max-width: 768px) {
  .show_options_button {
    transform: rotate(-90deg);
  }
}
@media screen and (min-width: 769px) {
  .show_options_button.closed {
    transform: scale(-1, 1);
  }
}
@media screen and (max-width: 768px) {
  .show_options_button.closed {
    transform: rotate(90deg);
  }
}
.show_options_button.closed .show_options_button__icon.arrow_icon {
  display: flex !important;
}
.show_options_button.closed .show_options_button__icon.close_icon {
  display: none !important;
}
.show_options_button:hover {
  color: #98261C;
  opacity: 0.6;
}
.show_options_button .show_options_button__icon {
  width: 32rem;
  height: 32rem;
  color: #98261C;
}
@media screen and (max-width: 1024px) {
  .show_options_button .show_options_button__icon {
    width: 24rem;
    height: 24rem;
  }
}
.show_options_button .show_options_button__icon.arrow_icon {
  display: none !important;
}

