@import "../../../css/helpers";

.input_size {
  @extend %flex_row_nowrap_flex_start_center;

  &:not(:last-child) {
    margin-bottom: 10rem;
  }

  label {
    padding-right: 20rem;
    font-size: 13rem;

    @include bp($point_2, min) {
      width: 90rem;
    }

    @include bp($point_3) {
      width: 110rem;
      flex: 0 0 auto;
    }
  }

  .input_wrapper {
    position: relative;
    width: 185rem;

    button {
      @extend %flex_row_wrap_center_center;

      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      width: 32rem;
      height: 32rem;
      padding: 0;
      margin: 0;

      border: none;
      background: transparent;
      cursor: pointer;

      transition: opacity 300ms ease-in-out;

      .icon {
        color: $red;
        width: 10rem;
        height: 20rem;
      }

      &:hover {
        opacity: .2;
      }

      &.increase {
        right: 0;
      }
      &.decrease {
        left: 0;
      }
    }

    input {
      height: 32rem;
      width: 100%;
      padding: 3rem 30rem;

      font-size: 13rem;
      text-align: center;

      border-radius: 6rem;
      border: 1px solid #E8E8E9;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }
    }
  }
}

