.input_size .input_wrapper button {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.input_size {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.font-1 {
  font-size: 30rem;
  font-weight: 700;
  line-height: 1.4;
}

.font-2 {
  font-size: 20rem;
  font-weight: 700;
  line-height: 1.4;
}

.font-3 {
  font-size: 16rem;
  font-weight: 500;
  line-height: 1;
}

.font-text {
  font-size: 14rem;
  font-weight: 500;
  line-height: 1.4;
}

.input_size:not(:last-child) {
  margin-bottom: 10rem;
}
.input_size label {
  padding-right: 20rem;
  font-size: 13rem;
}
@media screen and (min-width: 1025px) {
  .input_size label {
    width: 90rem;
  }
}
@media screen and (max-width: 1024px) {
  .input_size label {
    width: 110rem;
    flex: 0 0 auto;
  }
}
.input_size .input_wrapper {
  position: relative;
  width: 185rem;
}
.input_size .input_wrapper button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 32rem;
  height: 32rem;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: opacity 300ms ease-in-out;
}
.input_size .input_wrapper button .icon {
  color: #98261C;
  width: 10rem;
  height: 20rem;
}
.input_size .input_wrapper button:hover {
  opacity: 0.2;
}
.input_size .input_wrapper button.increase {
  right: 0;
}
.input_size .input_wrapper button.decrease {
  left: 0;
}
.input_size .input_wrapper input {
  height: 32rem;
  width: 100%;
  padding: 3rem 30rem;
  font-size: 13rem;
  text-align: center;
  border-radius: 6rem;
  border: 1px solid #E8E8E9;
  /* Firefox */
}
.input_size .input_wrapper input::-webkit-outer-spin-button, .input_size .input_wrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input_size .input_wrapper input[type=number] {
  -moz-appearance: textfield;
}

