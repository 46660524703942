@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,900&display=swap');

@import "./css/helpers/mixins";
@import "./css/helpers/variables";
@import "./css/helpers/flex_extends";
@import "./css/helpers/extends";
@import "./css/reset";

//------------------------------------------------------------base styles
:root {
  --full-screen: calc(var(--vh, 1vh) * 100);

  --option-panel-width: 480rem;
  --canvas-wrapper-mobile-height: 40vh;
  --canvas-wrapper-desktop-height: 230rem;

  @include bp($point_1) {
    --option-panel-width: 390rem;
  }
}

html {
  height: 100%;
  font: 400 calc(100vw / 1920 * 10) / 1.33 $font_1;

  @include bp($point_7 + 1, min) { // 376
    font-size: 1px;
  }

  @include bp($point_7) { // 375
    font-size: calc(100vw / 375);
  }
}

body {
  display: flex;
  height: 100%;

  font-size: $font_size_base;
  color: $color_text_base;

  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @supports (display: grid) {
    display: block;
  }

  @include bp($point_4) {
    overflow: hidden;
  }
}

.html,
.body,
.root,
#root {
  @include bp($point_4 + 1, min) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  @include bp($point_4) {

  }
}

//------------------------------------------------------------base styles###


//------------------------------------------------------------ preload_text
.preload_text {
  display: flex;
  flex-direction: column;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fff;

    animation-name: anim;
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-direction: revert;
    animation-fill-mode: forwards;

    @keyframes anim {
      from {
        left: 0;
      }
      to {
        left: 100%;
      }
    }
  }

  .progress {
    position: relative;

    width: 30%;
    height: 5px;
    margin-top: 20px;
    background: #f5f5f5;
    overflow: hidden;

    span {
      display: block;
      position: absolute;
      top: 0;
      left: - 100%;

      width: 50%;
      height: 100%;
      background: #98261c;

      animation-name: anim;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-direction: revert;
      animation-delay: 2000ms;

      @keyframes anim {
        from {
          left: - 100%;
        }
        to {
          left: 100%;
        }
      }
    }
  }
}
//------------------------------------------------------------ preload_text###


//------------------------------------------------------------ Leva styles
.leva-c-kWgxhW-bCBHqk-fill-false {
  left: 50%;
}
//------------------------------------------------------------ Leva styles###
