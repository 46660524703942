.reset_options_button {
  transition: opacity 0.3s ease;
  will-change: opacity;
}

.reset_options_button {
  transition: color 0.3s ease;
  will-change: color;
}

.reset_options_button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.font-1 {
  font-size: 30rem;
  font-weight: 700;
  line-height: 1.4;
}

.font-2 {
  font-size: 20rem;
  font-weight: 700;
  line-height: 1.4;
}

.font-3 {
  font-size: 16rem;
  font-weight: 500;
  line-height: 1;
}

.reset_options_button, .font-text {
  font-size: 14rem;
  font-weight: 500;
  line-height: 1.4;
}

.reset_options_button {
  position: fixed;
  top: 30rem;
  left: 50rem;
  z-index: 9;
  padding: 0;
  color: #222;
  cursor: pointer;
  border-radius: 0;
  border: 0;
}
.reset_options_button:hover {
  color: #98261C;
  opacity: 0.6;
}
@media screen and (max-width: 1024px) {
  .reset_options_button {
    top: 0;
    left: 0;
    padding: 8rem 10rem;
  }
}
.reset_options_button.hide {
  display: none;
}
.reset_options_button .title {
  margin-right: 20rem;
}
@media screen and (max-width: 1024px) {
  .reset_options_button .title {
    margin-right: 8rem;
  }
}
.reset_options_button .reset_options_button__icon {
  width: 32rem;
  height: 32rem;
  color: #98261C;
}
@media screen and (max-width: 1024px) {
  .reset_options_button .reset_options_button__icon {
    width: 24rem;
    height: 24rem;
  }
}

