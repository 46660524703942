@import "../../../css/helpers";

.additional_options {
  margin-top: 30rem;

  .additional_option__title {
    @extend %font-text;

    margin-bottom: 20rem;

    font-size: 13rem;

  }

  .additional_option__radio_buttons {
    @extend %flex_row_wrap_flex_start_center;
  }

  .additional_option__radio_button {
    &:not(:last-child) {
      margin-right: 50rem;
    }
  }

  .additional_option__radio {
    @extend %font-text;

    display: block;
    font-size: 13rem;
    opacity: .4;
    pointer-events: none;

    &.additional_option__radio--available {
      opacity: 1;
      pointer-events: initial;
    }

    input {
      position: absolute;
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      visibility: hidden;
    }

    &.radio--checked .label {
      &:before {
        border: 2.5rem solid $red;
      }
      &:after {
        display: block;
      }
    }

    .label {
      @extend %flex_row_wrap_flex_start_center;

      position: relative;
      height: 32rem;
      padding-left: 36rem;
      cursor: pointer;

      &:before {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate( 0, -50% );
        content: '';
        display: block;
        width: 32rem;
        height: 32rem;
        margin-right: 4rem;
        border-radius: 50%;
        border: 3rem solid $black_2;
      }

      &:after {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate( 6rem, -50% );
        content: '';
        display: none;
        width: 20rem;
        height: 20rem;
        border-radius: 50%;
        background: $red;
      }
    }
  }
}