#configurator {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;
}

.font-1 {
  font-size: 30rem;
  font-weight: 700;
  line-height: 1.4;
}

.font-2 {
  font-size: 20rem;
  font-weight: 700;
  line-height: 1.4;
}

.font-3 {
  font-size: 16rem;
  font-weight: 500;
  line-height: 1;
}

.font-text {
  font-size: 14rem;
  font-weight: 500;
  line-height: 1.4;
}

#configurator {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 768px) {
  #configurator {
    flex-direction: column;
  }
}

@media screen and (min-width: 769px) {
  #canvas_wrapper {
    position: relative;
    width: calc(100% - var(--option-panel-width));
  }
}
@media screen and (max-width: 768px) {
  #canvas_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    width: 100%;
    height: var(--canvas-wrapper-mobile-height);
    background-color: #fff;
    border-bottom: 1px solid #98261C;
    flex: 0 0 auto;
  }
}
@media screen and (min-width: 769px) {
  #canvas_wrapper.full_screen {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  #canvas_wrapper.full_screen {
    height: 100vh;
  }
}

.option_3d_info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 300px;
  padding: 20px;
  outline: 1px solid #ffffff;
  outline-offset: -5px;
  border-radius: 10px;
  color: #98261c;
  background: rgba(152, 38, 28, 0.7);
}
.option_3d_info .text {
  color: #fff;
}
.option_3d_info.hide {
  display: none;
}

.screen_saver {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-image: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100% auto;
  user-select: none;
  pointer-events: none;
}
.screen_saver.show {
  background-color: #000000;
  user-select: initial;
  pointer-events: initial;
}
.screen_saver.show .spinner {
  display: flex !important;
}
.screen_saver.change_oven .spinner {
  color: #98261c;
}
.screen_saver.change_oven .spinner + div div div {
  background: #ffffff !important;
}
.screen_saver.change_oven .spinner + div div div div {
  background: #98261c !important;
}
.screen_saver.change_oven .spinner + div div div span {
  color: #98261c !important;
}
.screen_saver .spinner {
  display: none !important;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1001;
  width: 24px;
  height: 24px;
  color: #ffffff;
  transform: translate(-50%, -35px);
}

