// Transitions
%transition_opacity {
	transition: opacity .3s ease;
	will-change: opacity;
}
%transition_color {
	transition: color .3s ease;
	will-change: color;
}
%transition_color_background {
	transition: color .3s ease, background .3s ease;
	will-change: color, background;
}
%transition_color_background_border {
	transition: color .3s ease, background .3s ease, border-color .3s ease ;
	will-change: color, background, border-color;
}


%flex_e {
	@include flex_block();
}

%flex_row_wrap_flex_start {
	@include flex_block(row, wrap, flex-start);
}

%flex_row_wrap_flex_start_stretch {
	@include flex_block(row, wrap, flex-start, stretch);
}

%flex_row_wrap_flex_start_center {
	@include flex_block(row, wrap, flex-start, center, center);
}

%flex_row_wrap_flex_start_flex_end {
	@include flex_block(row, wrap, flex-start, flex-end, flex-end);
}

%flex_row_wrap_flex_start_baseline {
	@include flex_block(row, wrap, flex-start, baseline);
}

%flex_row_wrap_flex_end {
	@include flex_block(row, wrap, flex-end);
}

%flex_row_wrap_flex_end_center {
	@include flex_block(row, wrap, flex-end, center, center);
}

%flex_row_wrap_flex_end_stretch {
	@include flex_block(row, wrap, flex-end, stretch);
}

%flex_row_wrap_flex_end_baseline {
	@include flex_block(row, wrap, flex-end, baseline);
}

%flex_row_wrap_center {
	@include flex_block(row, wrap, center);
}

%flex_row_wrap_center_center {
	@include flex_block(row, wrap, center, center, center);
}

%flex_row_wrap_center_stretch {
	@include flex_block(row, wrap, center, stretch);
}

%flex_row_wrap_center_baseline {
	@include flex_block(row, wrap, center, baseline);
}

%flex_row_wrap_space_between {
	@include flex_block(row, wrap, space-between);
}

%flex_row_wrap_space_between_stretch {
	@include flex_block(row, wrap, space-between, stretch);
}

%flex_row_wrap_space_between_center {
	@include flex_block(row, wrap, space-between, center, center);
}

%flex_row_wrap_space_between_baseline {
	@include flex_block(row, wrap, space-between, baseline);
}

%flex_row_wrap_space_between_flex_start {
	@include flex_block(row, wrap, space-between, flex-start);
}

%flex_row_wrap_space_between_flex_end {
	@include flex_block(row, wrap, space-between, flex-end);
}

%flex_row_nowrap_flex_start {
	@include flex_block(row, nowrap, flex-start);
}

%flex_row_nowrap_flex_start_stretch {
	@include flex_block(row, nowrap, flex-start, stretch);
}

%flex_row_nowrap_flex_start_center {
	@include flex_block(row, nowrap, flex-start, center, center);
}

%flex_row_nowrap_flex_start_flex_end {
	@include flex_block(row, nowrap, flex-start, flex-end);
}

%flex_row_nowrap_flex_start_baseline {
	@include flex_block(row, nowrap, flex-start, baseline);
}

%flex_row_nowrap_flex_end {
	@include flex_block(row, nowrap, flex-end);
}

%flex_row_nowrap_flex_end_center {
	@include flex_block(row, nowrap, flex-end, center, center);
}

%flex_row_nowrap_flex_end_stretch {
	@include flex_block(row, nowrap, flex-end, stretch);
}

%flex_row_nowrap_flex_end_baseline {
	@include flex_block(row, nowrap, flex-end, baseline);
}

%flex_row_nowrap_center {
	@include flex_block(row, nowrap, center);
}

%flex_row_nowrap_center_center {
	@include flex_block(row, nowrap, center, center, center);
}

%flex_row_nowrap_center_stretch {
	@include flex_block(row, nowrap, center, stretch);
}

%flex_row_nowrap_center_baseline {
	@include flex_block(row, nowrap, center, baseline);
}

%flex_row_nowrap_space_between {
	@include flex_block(row, nowrap, space-between);
}

%flex_row_nowrap_space_between_stretch {
	@include flex_block(row, nowrap, space-between, stretch);
}

%flex_row_nowrap_space_between_center {
	@include flex_block(row, nowrap, space-between, center, center);
}

%flex_row_nowrap_space_between_baseline {
	@include flex_block(row, nowrap, space-between, baseline);
}

%flex_row_nowrap_space_between_flex_start {
	@include flex_block(row, nowrap, space-between, flex-start);
}

%flex_row_nowrap_space_between_flex-end {
	@include flex_block(row, nowrap, space-between, flex-end);
}

%flex_column_wrap_flex_start {
	@include flex_block(column, wrap, flex-start);
}

%flex_column_wrap_flex_start_stretch {
	@include flex_block(column, wrap, flex-start, stretch);
}

%flex_column_wrap_flex_start_center {
	@include flex_block(column, wrap, flex-start, center, center);
}

%flex_column_wrap_flex_start_flex_end {
	@include flex_block(column, wrap, flex-start, flex-end);
}

%flex_column_wrap_flex_start_baseline {
	@include flex_block(column, wrap, flex-start, baseline);
}

%flex_column_wrap_flex_end {
	@include flex_block(column, wrap, flex-end);
}

%flex_column_wrap_flex_end_center {
	@include flex_block(column, wrap, flex-end, center, center);
}

%flex_column_wrap_flex_end_stretch {
	@include flex_block(column, wrap, flex-end, stretch);
}

%flex_column_wrap_flex_end_baseline {
	@include flex_block(column, wrap, flex-end, baseline);
}

%flex_column_wrap_center {
	@include flex_block(column, wrap, center);
}

%flex_column_wrap_center_center {
	@include flex_block(column, wrap, center, center, center);
}

%flex_column_wrap_center_stretch {
	@include flex_block(column, wrap, center, stretch);
}

%flex_column_wrap_center_baseline {
	@include flex_block(column, wrap, center, baseline);
}

%flex_column_wrap_space_between {
	@include flex_block(column, wrap, space-between);
}

%flex_column_wrap_space_between_stretch {
	@include flex_block(column, wrap, space-between, stretch);
}

%flex_column_wrap_space_between_center {
	@include flex_block(column, wrap, space-between, center);
}

%flex_column_wrap_space_between_baseline {
	@include flex_block(column, wrap, space-between, baseline);
}

%flex_column_wrap_space_between_flex_start {
	@include flex_block(column, wrap, space-between, flex-start);
}

%flex_column_wrap_space_between_flex_end {
	@include flex_block(column, wrap, space-between, flex-end);
}

%flex_column_nowrap_flex_start {
	@include flex_block(column, nowrap, flex-start);
}

%flex_column_nowrap_flex_start_stretch {
	@include flex_block(column, nowrap, flex-start, stretch);
}

%flex_column_nowrap_flex_start_center {
	@include flex_block(column, nowrap, flex-start, center);
}

%flex_column_nowrap_flex_start_flex_end {
	@include flex_block(column, nowrap, flex-start, flex-end);
}

%flex_column_nowrap_flex_start_baseline {
	@include flex_block(column, nowrap, flex-start, baseline);
}

%flex_column_nowrap_flex_end {
	@include flex_block(column, nowrap, flex-end);
}

%flex_column_nowrap_flex_end_center {
	@include flex_block(column, nowrap, flex-end, center);
}

%flex_column_nowrap_flex_end_stretch {
	@include flex_block(column, nowrap, flex-end, stretch);
}

%flex_column_nowrap_flex_end_baseline {
	@include flex_block(column, nowrap, flex-end, baseline);
}

%flex_column_nowrap_center {
	@include flex_block(column, nowrap, center);
}

%flex_column_nowrap_center_center {
	@include flex_block(column, nowrap, center, center);
}

%flex_column_nowrap_center_stretch {
	@include flex_block(column, nowrap, center, stretch);
}

%flex_column_nowrap_center_baseline {
	@include flex_block(column, nowrap, center, baseline);
}

%flex_column_nowrap_space_between {
	@include flex_block(column, nowrap, space-between);
}

%flex_column_nowrap_space_between_stretch {
	@include flex_block(column, nowrap, space-between, stretch);
}

%flex_column_nowrap_space_between_center {
	@include flex_block(column, nowrap, space-between, center);
}

%flex_column_nowrap_space_between_baseline {
	@include flex_block(column, nowrap, space-between, baseline);
}

%flex_column_nowrap_space_between_flex_start {
	@include flex_block(column, nowrap, space-between, flex-start);
}

%flex_column_nowrap_space_between_flex_end {
	@include flex_block(column, nowrap, space-between, flex-end);
}
