@import "../../../css/helpers";

.reset_options_button {
  @extend %flex_row_nowrap_center_center;
  @extend %font-text;
  @extend %transition_color;
  @extend %transition_opacity;

  position: fixed;
  top: 30rem;
  left: 50rem;
  z-index: 9;

  padding: 0;
  color: $black_2;

  cursor: pointer;
  border-radius: 0;
  border: 0;

  &:hover {
    color: $red;
    opacity: .6;
  }

  @include bp($point_3) {
    top: 0;
    left: 0;
    padding: 8rem 10rem;
  }

  &.hide {
    display: none;
  }

  .title {
    margin-right: 20rem;

    @include bp($point_3) {
      margin-right: 8rem;
    }
  }

  .reset_options_button__icon {
    width: 32rem;
    height: 32rem;

    color: $red;

    @include bp($point_3) {
      width: 24rem;
      height: 24rem;
    }
  }
}