.checkbox__price, .checkbox__title {
  transition: color 0.3s ease;
  will-change: color;
}

.checkbox__label {
  transition: color 0.3s ease, background 0.3s ease, border-color 0.3s ease;
  will-change: color, background, border-color;
}

.options_panel__checkboxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;
}

.checkbox__label_in, .checkbox__label {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.font-1 {
  font-size: 30rem;
  font-weight: 700;
  line-height: 1.4;
}

.font-2 {
  font-size: 20rem;
  font-weight: 700;
  line-height: 1.4;
}

.font-3 {
  font-size: 16rem;
  font-weight: 500;
  line-height: 1;
}

.font-text {
  font-size: 14rem;
  font-weight: 500;
  line-height: 1.4;
}

.checkbox__price, .checkbox__title {
  font-size: 13rem;
  font-weight: 400;
  line-height: 1.4;
}

.options_panel__section_title {
  margin-bottom: 20rem;
  font-size: 20rem;
  font-weight: 700;
}

.options_panel__checkboxes {
  margin: 0 -10rem -10rem 0;
}
@media (max-width: 1100px) and (min-width: 769px) {
  .options_panel__checkboxes {
    margin: 0 -8rem -8rem 0;
  }
}
@media (max-width: 768px) and (min-width: 481px) {
  .options_panel__checkboxes {
    margin: 0 -10rem -10rem 0;
  }
}
@media screen and (max-width: 480px) {
  .options_panel__checkboxes {
    margin: 0 -8rem -8rem 0;
  }
}

.checkbox_item {
  width: 25%;
  padding: 0 10rem 10rem 0;
  opacity: 0.4;
  pointer-events: none;
}
@media (max-width: 1100px) and (min-width: 769px) {
  .checkbox_item {
    width: 33.33333%;
    padding: 0 8rem 8rem 0;
  }
}
@media (max-width: 768px) and (min-width: 481px) {
  .checkbox_item {
    width: 25%;
    padding: 0 10rem 10rem 0;
  }
}
@media screen and (max-width: 480px) {
  .checkbox_item {
    width: 33.33333%;
    padding: 0 8rem 8rem 0;
  }
}
.checkbox_item.checkbox_item--available {
  opacity: 1;
  pointer-events: initial;
}
.checkbox_item.checkbox_item--hide {
  position: absolute;
  opacity: 0;
  user-select: none;
  pointer-events: none;
}

.checkbox_item__in {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  user-select: none;
}

.checkbox__element {
  display: block;
  width: 100%;
  height: 100%;
}

.checkbox__label {
  width: 100%;
  height: 100%;
  border: 2rem solid #E8E8E9;
  border-radius: 10rem;
  overflow: hidden;
  background: #fff;
  cursor: pointer;
}
.checkbox_item--checked .checkbox__label {
  background: #98261C;
  border: 2rem solid #98261C;
}
.checkbox_item--checked .checkbox__label .checkbox__title,
.checkbox_item--checked .checkbox__label .checkbox__price {
  color: #fff;
}

.checkbox__img {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 100%;
  background-color: #fff;
  overflow: hidden;
}
.image_hor_flip .checkbox__img {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
.checkbox__img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.checkbox__label_in {
  width: 100%;
  padding: 10rem 3rem 7rem;
  user-select: none;
}
@media screen and (max-width: 375px) {
  .checkbox__label_in {
    min-height: 101rem !important;
  }
}

.checkbox__title {
  display: block;
  width: 100%;
  padding-bottom: 12rem;
  line-height: 1.1;
}

.checkbox__price {
  display: block;
  margin-top: auto;
  color: #98261C;
  line-height: 1.1;
}

