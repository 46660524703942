@import "../../../css/helpers";

.checkbox__tooltip_button {
  @extend %flex_row_wrap_center_center;

  color: inherit;
  border-radius: 50%;
  cursor: pointer;

  .icon_info {
    color: inherit;
  }

  .checkbox_item & {
    position: absolute;
    top: 10rem;
    right: 10rem;

    width: 19rem;
    height: 19rem;

    border: 1rem solid $black;
    background-color: $white;

    .icon_info {
      width: 3rem;
      height: 7rem;
      margin-top: 0.5rem;
    }
  }

  .options_panel__header & {
    width: 31rem;
    height: 31rem;

    color: $white;
    background-color: $red;

    .icon_info {
      width: 8rem;
      height: 12rem;
      margin-left: 0.5rem;
    }
  }
}

.option_small_tooltip {
  display: block;
  position: absolute;
  z-index: 999;

  min-width: 300rem;
  padding: 20rem;

  background: $white;
  border-radius: 20rem;
  border: 1rem solid $red;

  box-shadow: 0 0 30rem -10rem $red;

  @include bp($point_4 + 1, min) {
    width: 300rem !important;
  }

  &.hide {
    top: -100000;
    left: -100000;
    pointer-events: none;
    opacity: 0;
    z-index: -100000;
  }

  .arrow {
    position: absolute;
    content: '';

    @include bp($point_4 + 1, min) {
      $size: 20rem;

      top: 20rem;
      right: calc(-1 * $size * 2);
      border: $size solid transparent;
      border-left: $size solid $red;
    }

    @include bp($point_4) {
      $size: 30rem;

      z-index: -1;
      bottom: calc(-1 * $size);
      left: 20rem;

      width: 0;
      height: 0;
      border-style: solid;
      border-width: $size $size 0 0;
      border-color: $red transparent transparent transparent;

      &.flip {
        transform: scale(-1, 1);
      }
    }
  }

  .close_tooltip_button {
    position: absolute;
    top: 0;
    right: 0;

    width: 50rem;
    height: 50rem;

    &:hover,
    &:focus {
      opacity: .6;
    }

    .icon_wrapper {
      position: absolute;
      top: 8rem;
      right: 8rem;

      display: block;
      width: 20rem;
      height: 20rem;

      cursor: pointer;
    }

    .close_icon {
      width: 100%;
      height: 100%;
    }
  }

  .image {
    display: none;
  }

  .title {
    display: block;
    margin-bottom: 20rem;

    font-weight: 700;
    font-size: 18rem;
    line-height: 1;
  }

  .content {
    font-weight: 400;
    font-size: 14rem;
    line-height: 1.2;
  }
}

