@use "sass:math";

@mixin font_face($file_name,$font_name:$file_name,$path:$font_path,$weight:normal,$style:normal) {
	@font-face {
		font-family: quote($font_name);
		src: url($path + $file-name + ".ttf") format("ttf"), url($path + $file-name + ".woff2") format("woff2"), url($path + $file-name + ".woff") format("woff");
		font-weight: $weight;
		font-style: $style;
		font-display: swap;
	}
}

@mixin bp($width,$value:max) {
	@if $value == min {
		@media screen and (min-width: $width) {
			@content;
		}
	}
	@else if $value == max {
		@media screen and (max-width: $width) {
			@content;
		}
	}
	@else {
		@media (max-width: $width) and (min-width: $value) {
			@content;
		}
	}
}

@mixin bph($width,$value:max) {
	@if $value == min {
		@media screen and (min-height: $width) {
			@content;
		}
	}
	@else if $value == max {
		@media screen and (max-height: $width) {
			@content;
		}
	}
	@else {
		@media (max-height: $width) and (min-height: $value) {
			@content;
		}
	}
}

@mixin appearance($value: button) {
	-moz-appearance: $value;
	-webkit-appearance: $value;
	appearance: $value;
}

@mixin flex_block(
	$flex-direction : row,
	$flex-wrap: wrap,
	$content : space-between,
	$align-items : flex-start,
	$align-content : flex-start) {

	display: flex;
	flex-direction: $flex-direction;
	flex-wrap: $flex-wrap;
	justify-content: $content;
	align-content: $align-content;
	align-items: $align-items;
}

@mixin clearfix {
	&:after {
		content: "";
		display: block;
		height: 0;
		clear: both;
		visibility: hidden;
	}
}
