@import "./css/helpers";

//--------------------------------------------- Configurator
#configurator {
  @extend %flex_row_nowrap_flex_start_stretch;

  width: 100%;
  height: 100%;

  @include bp($point_4 + 1, min) {

  }

  @include bp($point_4) {
    flex-direction: column;
  }
}

#canvas_wrapper {
  @include bp($point_4 + 1, min) {
    position: relative;
    width: calc(100% - var(--option-panel-width));
  }

  @include bp($point_4) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    width: 100%;
    height: var(--canvas-wrapper-mobile-height);

    background-color: $white;
    border-bottom: 1px solid #98261C;

    flex: 0 0 auto;
  }

  &.full_screen {
    @include bp($point_4 + 1, min) {
      width: 100%;
    }

    @include bp($point_4) {
      height: 100vh;
    }
  }
}
//--------------------------------------------- Configurator####

//--------------------------------------------- Info
.option_3d_info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  max-width: 300px;
  padding: 20px;
  outline: 1px solid #ffffff;
  outline-offset: -5px;
  border-radius: 10px;

  color: #98261c;
  background: rgba(#98261c, 0.7);

  .text {
    color: #fff;
  }

  &.hide {
    display: none;
  }
}
//--------------------------------------------- Info###

//--------------------------------------------- ScreenSaver
.screen_saver {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background-image: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100% auto;

  user-select: none;
  pointer-events: none;

  &.show {
    background-color: #000000;
    user-select: initial;
    pointer-events: initial;

    .spinner {
      display: flex !important;
    }
  }

  &.change_oven {
    .spinner {
      color: #98261c;
    }
    .spinner + div div div { // bar
      background: #ffffff !important;
    }
    .spinner + div div div div { // progress
      background: #98261c !important;
    }
    .spinner + div div div span { // text
      color: #98261c !important;
    }
  }

  .spinner {
    display: none !important;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1001;
    width: 24px;
    height: 24px;
    color: #ffffff;
    transform: translate(-50%, -35px);
  }
}
//--------------------------------------------- ScreenSaver###

//--------------------------------------------- Spinner


//--------------------------------------------- Spinner###
