.checkbox__tooltip_button {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.font-1 {
  font-size: 30rem;
  font-weight: 700;
  line-height: 1.4;
}

.font-2 {
  font-size: 20rem;
  font-weight: 700;
  line-height: 1.4;
}

.font-3 {
  font-size: 16rem;
  font-weight: 500;
  line-height: 1;
}

.font-text {
  font-size: 14rem;
  font-weight: 500;
  line-height: 1.4;
}

.checkbox__tooltip_button {
  color: inherit;
  border-radius: 50%;
  cursor: pointer;
}
.checkbox__tooltip_button .icon_info {
  color: inherit;
}
.checkbox_item .checkbox__tooltip_button {
  position: absolute;
  top: 10rem;
  right: 10rem;
  width: 19rem;
  height: 19rem;
  border: 1rem solid #000;
  background-color: #fff;
}
.checkbox_item .checkbox__tooltip_button .icon_info {
  width: 3rem;
  height: 7rem;
  margin-top: 0.5rem;
}
.options_panel__header .checkbox__tooltip_button {
  width: 31rem;
  height: 31rem;
  color: #fff;
  background-color: #98261C;
}
.options_panel__header .checkbox__tooltip_button .icon_info {
  width: 8rem;
  height: 12rem;
  margin-left: 0.5rem;
}

.option_small_tooltip {
  display: block;
  position: absolute;
  z-index: 999;
  min-width: 300rem;
  padding: 20rem;
  background: #fff;
  border-radius: 20rem;
  border: 1rem solid #98261C;
  box-shadow: 0 0 30rem -10rem #98261C;
}
@media screen and (min-width: 769px) {
  .option_small_tooltip {
    width: 300rem !important;
  }
}
.option_small_tooltip.hide {
  top: -100000;
  left: -100000;
  pointer-events: none;
  opacity: 0;
  z-index: -100000;
}
.option_small_tooltip .arrow {
  position: absolute;
  content: "";
}
@media screen and (min-width: 769px) {
  .option_small_tooltip .arrow {
    top: 20rem;
    right: -40rem;
    border: 20rem solid transparent;
    border-left: 20rem solid #98261C;
  }
}
@media screen and (max-width: 768px) {
  .option_small_tooltip .arrow {
    z-index: -1;
    bottom: -30rem;
    left: 20rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30rem 30rem 0 0;
    border-color: #98261C transparent transparent transparent;
  }
  .option_small_tooltip .arrow.flip {
    transform: scale(-1, 1);
  }
}
.option_small_tooltip .close_tooltip_button {
  position: absolute;
  top: 0;
  right: 0;
  width: 50rem;
  height: 50rem;
}
.option_small_tooltip .close_tooltip_button:hover, .option_small_tooltip .close_tooltip_button:focus {
  opacity: 0.6;
}
.option_small_tooltip .close_tooltip_button .icon_wrapper {
  position: absolute;
  top: 8rem;
  right: 8rem;
  display: block;
  width: 20rem;
  height: 20rem;
  cursor: pointer;
}
.option_small_tooltip .close_tooltip_button .close_icon {
  width: 100%;
  height: 100%;
}
.option_small_tooltip .image {
  display: none;
}
.option_small_tooltip .title {
  display: block;
  margin-bottom: 20rem;
  font-weight: 700;
  font-size: 18rem;
  line-height: 1;
}
.option_small_tooltip .content {
  font-weight: 400;
  font-size: 14rem;
  line-height: 1.2;
}

