.options_footer .form_button_summary {
  transition: opacity 0.3s ease;
  will-change: opacity;
}

.options_footer .options_footer__tab .title {
  transition: color 0.3s ease, background 0.3s ease;
  will-change: color, background;
}

.options_footer .options_footer__tabs_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.options_footer .options_footer__button_wrap.options_footer__button_wrap--mobile, .options_panel__section .options_panel__header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.options_footer .options_footer__button_wrap.options_footer__button_wrap--mobile .price_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-end;
  align-items: flex-end;
}

.options_footer .options_footer__total_price {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: flex-start;
  align-items: baseline;
}

.options_footer .options_footer__tab_in {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.options_footer .options_footer__button_wrap.options_footer__button_wrap--mobile .price {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-end;
}

.options_footer .form_button_summary, .font-1 {
  font-size: 30rem;
  font-weight: 700;
  line-height: 1.4;
}

.contact_form .contact_desc, .contact_form .contact_title, .options_panel__title, .font-2 {
  font-size: 20rem;
  font-weight: 700;
  line-height: 1.4;
}

.contact_form .contact_form__input input, .options_panel__section .options_panel__header, .font-3 {
  font-size: 16rem;
  font-weight: 500;
  line-height: 1;
}

.contact_form__info .info_text, .options_footer .options_footer__total_price, .font-text {
  font-size: 14rem;
  font-weight: 500;
  line-height: 1.4;
}

/* SIDEBAR */
#options_panel {
  transition: transform 300ms ease-in-out;
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  #options_panel {
    width: var(--option-panel-width);
    height: 100vh;
  }
}
@media screen and (max-width: 768px) {
  #options_panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin-bottom: 110rem;
    margin-top: var(--canvas-wrapper-mobile-height);
  }
}
#options_panel.hide {
  display: none;
}
#options_panel form {
  overflow: hidden;
}

.options_panel__in {
  display: block;
  padding: 47rem 10rem 17rem;
  overflow-x: hidden;
  background-color: #fff;
}
@media screen and (max-width: 1100px) {
  .options_panel__in {
    padding: 47rem 6rem 17rem;
  }
}
@media screen and (min-width: 769px) {
  .options_panel__in {
    height: calc(100vh - var(--canvas-wrapper-desktop-height));
  }
}
@media screen and (max-width: 768px) {
  .options_panel__in {
    max-height: calc(100vh - var(--canvas-wrapper-mobile-height) - 100rem);
    height: 100%;
    padding-top: 12rem;
    padding-bottom: 80rem;
    overflow-x: hidden;
    -ms-overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-y: auto;
  }
}
@media screen and (max-height: 600px) and (min-width: 768px) {
  .options_panel__in {
    height: calc(100vh - 110rem);
  }
}

@media screen and (max-width: 768px) {
  .options_panel__options_list {
    margin-bottom: -30rem;
    overflow: hidden;
    -ms-overflow: hidden;
  }
}

.options_panel__title {
  margin-bottom: 10rem;
}
@media screen and (max-width: 768px) {
  .options_panel__title {
    display: none;
  }
}

.options_panel__section {
  margin-bottom: 30rem;
}
.options_panel__section .options_panel__header {
  margin-bottom: 10rem;
}
.options_panel__section .options_panel__header .title {
  margin-right: 20rem;
  font-weight: 500;
}

/* FOOTER */
.options_footer {
  padding: 10rem 10rem;
  background-color: #fff;
}
@media screen and (min-width: 769px) {
  .options_footer {
    position: fixed;
    bottom: 0;
    right: 0;
    width: var(--option-panel-width);
    box-shadow: 10px -1rem 20rem -14rem #98261C;
  }
}
@media screen and (max-width: 768px) {
  .options_footer {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    box-shadow: 10px -1rem 20rem -14rem #98261C;
    padding: 0 4rem 6rem;
    transition: transform 0.3s ease;
    will-change: transform;
    transform: translateY(200rem);
  }
}
@media screen and (max-height: 600px) {
  .options_footer {
    padding: 3rem 6rem;
  }
}
.options_footer.show_half {
  transform: translateY(66rem);
}
.options_footer.show {
  transform: translateY(0);
}
.options_footer .options_footer__total_price {
  padding: 0 0 4rem 0;
}
@media screen and (max-width: 768px) {
  .options_footer .options_footer__total_price {
    display: none;
  }
}
.options_footer .options_footer__total_price .title {
  font-size: 13rem;
  margin-right: auto;
}
.options_footer .options_footer__total_price .price {
  font-size: 20rem;
  font-weight: 700;
  margin-left: 10rem;
  color: #98261C;
}
.options_footer .options_footer__total_price .notification_taxes {
  font-size: 12rem;
  margin-right: 10rem;
}
.options_footer .options_footer__total_price .amount {
  font-size: 20rem;
}
.options_footer .options_footer__total_price .currency_symbol {
  margin-left: 5rem;
}
.options_footer .options_footer__tabs {
  padding: 20rem 0 6rem 0;
  border-top: 1rem solid #98261C;
}
@media screen and (max-height: 600px) {
  .options_footer .options_footer__tabs {
    padding: 5rem 0;
  }
}
@media screen and (max-width: 768px) {
  .options_footer .options_footer__tabs {
    padding-top: 8rem;
  }
}
.options_footer .options_footer__tabs_list {
  margin: 0 -10rem -10rem 0;
}
.options_footer .options_footer__tab {
  width: 25%;
  padding: 0 10rem 10rem 0;
}
.options_footer .options_footer__tab .title {
  width: 100%;
  padding: 10rem 3rem;
  color: #000;
  font-size: 12rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}
@media screen and (max-height: 600px) {
  .options_footer .options_footer__tab .title {
    padding: 3rem;
  }
}
@media screen and (max-width: 768px) {
  .options_footer .options_footer__tab .icon_wrap {
    display: none;
  }
}
.options_footer .options_footer__tab .icon {
  width: 60rem;
  height: 60rem;
  color: #222;
  display: block;
}
@media screen and (max-height: 600px) {
  .options_footer .options_footer__tab .icon {
    width: 40rem;
    height: 40rem;
  }
}
.options_footer .options_footer__tab.active .title {
  color: #fff;
  background: #98261C;
}
.options_footer .options_footer__tab.active .options_footer__tab_in {
  border-color: #98261C;
}
.options_footer .options_footer__tab_in {
  border: 2rem solid #E8E8E9;
  border-radius: 10rem;
  overflow: hidden;
  cursor: pointer;
  transition: border-color 0.3s ease;
  will-change: border-color;
}
.options_footer__tab.active .options_footer .options_footer__tab_in {
  border-color: #98261C;
}
@media screen and (max-width: 768px) {
  .options_footer .options_footer__button_wrap.options_footer__button_wrap--desktop {
    display: none;
  }
}
@media screen and (min-width: 769px) {
  .options_footer .options_footer__button_wrap.options_footer__button_wrap--mobile {
    user-select: none;
    pointer-events: none;
    opacity: 0;
    position: absolute;
  }
}
.options_footer .options_footer__button_wrap.options_footer__button_wrap--mobile .form_button_summary {
  width: 156rem;
  padding-bottom: 2rem;
  line-height: 40rem;
}
.options_footer .options_footer__button_wrap.options_footer__button_wrap--mobile .price_wrapper {
  height: 100%;
  margin-left: auto;
}
.options_footer .options_footer__button_wrap.options_footer__button_wrap--mobile .title {
  margin-bottom: 2rem;
  font-size: 13rem;
  font-weight: 700;
}
.options_footer .options_footer__button_wrap.options_footer__button_wrap--mobile .price {
  font-size: 20rem;
  font-weight: 700;
  margin: 0 5rem 0 32rem;
}
.options_footer .options_footer__button_wrap.options_footer__button_wrap--mobile .price .notification_taxes {
  font-size: 10rem;
  font-weight: 500;
  color: #222222;
}
.options_footer .options_footer__button_wrap.options_footer__button_wrap--mobile .price .amount {
  font-size: 20rem;
  font-weight: 700;
  color: #98261C;
}
.options_footer .options_footer__button_wrap.options_footer__button_wrap--mobile .price .currency_symbol {
  margin-left: 5rem;
}
.options_footer .options_footer__footer_mobile .price {
  font-size: 20rem;
  font-weight: 700;
  margin-left: 10rem;
  color: #98261C;
}
.options_footer .options_footer__footer_mobile .notification_taxes {
  font-size: 12rem;
  margin-right: 10rem;
}
.options_footer .options_footer__footer_mobile .amount {
  font-size: 20rem;
}
.options_footer .options_footer__footer_mobile .currency_symbol {
  margin-left: 5rem;
}
.options_footer .form_button_summary {
  width: 100%;
  padding: 0 5rem;
  color: #fff;
  background-color: #98261C;
  font-size: 20rem;
  line-height: 54rem;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 10rem;
}
.options_footer .form_button_summary[disabled=true] {
  opacity: 0.4;
  user-select: none;
  pointer-events: none;
}
.options_footer .form_button_summary:hover {
  opacity: 0.6;
}
@media screen and (max-height: 600px) {
  .options_footer .form_button_summary {
    font-size: 14rem;
    line-height: 30rem;
  }
}

/* Contact Form */
.contact_form {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.38);
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact_form .contact_form__wrapper {
  position: relative;
  padding: 40px 20px 30px;
  background: #F2F2F2;
  max-width: min(98%, 700px);
  max-height: 98.4vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.contact_form .contact_form__close_btn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px 10px 0 0;
}
.contact_form .contact_form__close_btn .contact_form__close_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32rem;
  height: 32rem;
  color: #98261C;
  cursor: pointer;
  opacity: 0.2;
  transition: opacity 200ms ease-in-out;
}
.contact_form .contact_form__close_btn .contact_form__close_icon:hover {
  opacity: 1;
}
.contact_form .contact_title {
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .contact_form .contact_title {
    font-size: 16px;
  }
}
.contact_form .contact_desc {
  margin-bottom: 20px;
  max-width: 700px;
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .contact_form .contact_desc {
    font-size: 14px;
  }
}
.contact_form .contact_form__form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.contact_form .contact_form__input {
  width: 100%;
  color: #222222;
  margin-bottom: 15px;
}
@media screen and (min-width: 769px) {
  .contact_form .contact_form__input {
    width: 49%;
  }
}
@media screen and (max-width: 768px) {
  .contact_form .contact_form__input {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .contact_form .contact_form__input.contact_form__input--submit {
    order: 1;
  }
}
.contact_form .contact_form__input input {
  padding: 10px 35px;
  border: none;
  width: 100%;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  .contact_form .contact_form__input input {
    font-size: 14px;
  }
}
.contact_form .contact_form__input input::placeholder {
  color: #7e7e7e;
  opacity: 1; /* Firefox */
}
.contact_form .contact_form__input input::-ms-input-placeholder { /* Edge 12 -18 */
  color: #7e7e7e;
}
.contact_form .contact_form__input input.error {
  outline: 1px solid red;
  outline-offset: 5px;
  border-radius: 2px;
}
.contact_form .contact_form__input input[type=submit] {
  background: #98261C;
  color: #fff;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;
}
.contact_form .contact_form__input input[type=submit]:hover, .contact_form .contact_form__input input[type=submit]:focus {
  opacity: 0.6;
}
.contact_form .contact_form__input input[type=submit]:disabled, .contact_form .contact_form__input input[type=submit][disabled] {
  background-color: #cccccc;
  color: #666666;
  opacity: 1;
  cursor: initial;
}
.contact_form .contact_form__checkbox {
  width: 100%;
  margin-bottom: 15px;
  font-size: 16px;
  transition: opacity 200ms ease-in-out;
}
.contact_form .contact_form__checkbox label {
  display: flex;
  width: max-content;
  cursor: pointer;
  max-width: 100%;
}
@media screen and (max-width: 768px) {
  .contact_form .contact_form__checkbox label {
    font-size: 14px;
  }
}
.contact_form .contact_form__checkbox label:hover, .contact_form .contact_form__checkbox label:focus {
  opacity: 0.6;
}
.contact_form .contact_form__checkbox input {
  accent-color: #98261C;
}
.contact_form .contact_form__checkbox input.error {
  outline: 1px solid red;
  outline-offset: 5px;
  border-radius: 2px;
}
.contact_form .contact_form__checkbox span {
  display: inline-block;
  margin-left: 10px;
}
.contact_form .contact_form__spinner {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
}
.contact_form .contact_form__spinner .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  color: #98261c;
  transform: translate(-50%, -50%);
}

.contact_form__info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px;
  transform: translate(0, -100%);
  transition: transform 200ms ease-in-out;
  background: #63c753;
}
.contact_form__info.show {
  transform: translate(0, 0);
}
.contact_form__info .close_icon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 30px;
  height: 30px;
  color: #ffffff;
  cursor: pointer;
}
.contact_form__info .info_text {
  margin-left: 60px;
  color: #ffffff;
}

